<template>
    <div id="my-account" class="mb-5">
        <div class="main-title">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h3><i class="bi bi-person-fill mr-3"></i>Mon compte</h3>
                        <div class="underline"></div>
                    </div>
                </div>
            </div>
        </div>
        <profile-form type="show"></profile-form>
    </div>
</template>

<script>
    import ProfileForm from '@/components/front/profile/ProfileForm'
    export default {
        name: "MyAccount",
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Mon compte',
        },
        components: {
            ProfileForm
        },
    }
</script>

<style scoped>

</style>
